import { createTheme, responsiveFontSizes } from '@mui/material/styles'

export let theme = createTheme({
  spacing: 5,
  palette: {
    mode: 'dark',
    primary: {
      main: '#E1C04F',
      light: '#FEE87E',
      dark: '#BF893B',
      contrastText: 'rgba(0, 0, 0, 0.8)',
    },

    secondary: {
      main: '#1C1045 ',
      light: '#48398D',
      dark: '#090720',
      contrastText: '#fff',
    },

    text: {
      primary: '#404040',
    },
  },

  typography: {
    h1: {
      fontFamily: 'DM Serif Text',
    },
    h2: {
      fontFamily: 'DM Serif Text',
    },
    h3: {
      fontFamily: 'DM Serif Text',
    },
    h4: {
      fontFamily: 'DM Serif Text',
    },
  },
})

theme = responsiveFontSizes(theme)
