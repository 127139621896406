import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { User } from 'models/user'

interface AuthState {
  token?: string
  gameToken?: string
  user: User | null
  userName?: string
}

const initialState: AuthState = {
  token: '',
  gameToken: '',
  user: null,
  userName: '',
}

const auth = 'auth'

export const authSlice = createSlice({
  name: auth,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },

    setGameToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },

    username: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },

    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload
      state.gameToken = action.payload?.token || ''
    },
  },
})

export const authAction = authSlice.actions
export const authReducer = authSlice.reducer

export const selectToken = (state: RootState) => state.auth.token
export const selectGameToken = (state: RootState) => state.auth.gameToken
export const selectUser = (state: RootState) => state.auth.user
export const selectUserName = (state: RootState) => state.auth.userName
