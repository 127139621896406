export const translationEn = {
  profile: 'Profile',
  logout: 'Logout',
  login: 'Login',
  'sign up': 'Sign up',
  'play now': 'Play now',
  lore: 'Lore',
  marketplace: 'Marketplace',
  media: 'Media',
  'COMPETE FOR THE VICTORY': 'COMPETE FOR THE VICTORY',
  'Play against other players to be the king or queen of the valley.':
    'Play against other players to be the king or queen of the valley.',

  'MANAGE YOUR RESOURCES': 'MANAGE YOUR RESOURCES',
  'Create weapons, improve your troops and select your perfect deck.':
    'Create weapons, improve your troops and select your perfect deck.',

  'CREATE STRATEGIES TO DEFEAT YOUR ENEMIES': 'CREATE STRATEGIES TO DEFEAT YOUR ENEMIES',
  'Fight for the future of your people! Attack your enemies and defend your kingdom.':
    'Fight for the future of your people! Attack your enemies and defend your kingdom.',

  'DISCOVER THE SECRETS BEHIND OUR LANDS': 'DISCOVER THE SECRETS BEHIND OUR LANDS',
  'In an arid desert world, mysterious groups of mountains hidden paradises full of wealth. What is the origin of the valleys? What drives our characters to their destiny?':
    'In an arid desert world, mysterious groups of mountains hidden paradises full of wealth. What is the origin of the valleys? What drives our characters to their destiny?',

  'PLAY ALONE OR WITH FRIENDS': 'PLAY ALONE OR WITH FRIENDS',
  'Be the protagonist of your own story or unify your strength with other people.':
    'Be the protagonist of your own story or unify your strength with other people.',

  'PLAY TO OWN': 'PLAY TO OWN',
  'What you buy is yours forever! Get the best cards and customize your appearance.':
    'What you buy is yours forever! Get the best cards and customize your appearance.',
  PARTNERS: 'PARTNERS',
  'ABOUT US': 'ABOUT US',
  HELP: 'HELP',
  COMMUNITY: 'COMMUNITY',
  community_note: 'Terms & Conditions | Privacy Policy',
  'Our Team': 'Our Team',
  'Contact Us': 'Contact Us',
  "Don't have an account?": "Don't have an account?",
  'Create Account': 'Create Account',
  'Already have an account?': 'Already have an account?',
}
