import AOS from 'aos'
import 'aos/dist/aos.css'
import { lazy, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

const Main = lazy(() => import('features/Main/Main'))
const Review = lazy(() => import('features/Review/pages/Review'))

function App() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  if (process.env.REACT_APP_ENVIRONMENT === 'product') {
    return <Review />
  }

  return (
    <Routes>
      <Route path="/*" element={<Main />} />
      <Route path="review" element={<Review />} />
    </Routes>
  )
}

export default App
