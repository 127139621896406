export const translationPr = {
  profile: 'Perfil',
  logout: 'Sair',
  login: 'Conecte-se',
  'sign up': 'Inscrever-se',
  'play now': 'Jogue agora',
  lore: 'Lendário',
  marketplace: 'Mercado',
  media: 'Meios de comunicação',
  'COMPETE FOR THE VICTORY': 'COMPETE PELA VITÓRIA',
  'Play against other players to be the king or queen of the valley.':
    'Jogue contra outros jogadores para ser o rei ou a rainha do vale.',

  'MANAGE YOUR RESOURCES': 'GERENCIE SEUS RECURSOS',
  'Create weapons, improve your troops and select your perfect deck.':
    'Crie armas, melhore suas tropas e selecione seu baralho perfeito.',

  'CREATE STRATEGIES TO DEFEAT YOUR ENEMIES': 'CRIE ESTRATÉGIAS PARA DERROTAR SEUS INIMIGOS',
  'Fight for the future of your people! Attack your enemies and defend your kingdom.':
    'Lute pelo futuro do seu povo! Ataque seus inimigos e defenda seu reino.',

  'DISCOVER THE SECRETS BEHIND OUR LANDS': 'DESCUBRA OS SEGREDOS POR TRÁS DA NOSSA TERRA',
  'In an arid desert world, mysterious groups of mountains hidden paradises full of wealth. What is the origin of the valleys? What drives our characters to their destiny?':
    'Em um mundo árido e desértico, misteriosos grupos de montanhas escondem paraísos repletos de riquezas. Qual a origem dos vouchers? O que leva nossos personagens ao seu destino?',

  'PLAY ALONE OR WITH FRIENDS': 'JOGUE SOZINHO OU COM AMIGOS',
  'Be the protagonist of your own story or unify your strength with other people.':
    'Seja o protagonista de sua própria história ou junte forças com outras pessoas.',

  'PLAY TO OWN': 'JOGUE POR PRÓPRIO',
  'What you buy is yours forever! Get the best cards and customize your appearance.':
    'O que você compra é seu para sempre! Obtenha as melhores cartas e personalize sua aparência.',

  PARTNERS: 'PARCEIROS',
  'ABOUT US': 'SOBRE NÓS',
  HELP: 'AJUDA',
  COMMUNITY: 'COMUNIDADE',
  community_note: 'Termos e Condições | política de Privacidade',
  'Our Team': 'Nosso time',
  'Contact Us': 'Contate-nos',
  "Don't have an account?": 'Não tem uma conta?',
  'Create Account': 'Crie a sua conta aqui',
  'Already have an account?': 'Já tem uma conta?',
}
