export const translationVn = {
  profile: 'Hồ sơ',
  logout: 'Đăng xuất',
  login: 'Đăng nhập',
  'sign up': 'Đăng ký',
  'play now': 'Chơi ngay',
  lore: 'Truyền thuyết',
  marketplace: 'Cửa hàng',
  media: 'Truyền thông',

  'COMPETE FOR THE VICTORY': 'CHIẾN ĐẤU VÌ CHIẾN THẮNG',
  'Play against other players to be the king or queen of the valley.':
    'Chơi với những người chơi khác để trở thành vua hoặc nữ hoàng của thung lũng.',

  'MANAGE YOUR RESOURCES': 'QUẢN LÝ NGUỒN LỰC',
  'Create weapons, improve your troops and select your perfect deck.':
    'Tạo vũ khí, cải thiện quân đội của bạn và chọn bộ bài hoàn hảo của bạn.',

  'CREATE STRATEGIES TO DEFEAT YOUR ENEMIES': 'TẠO CHIẾN LƯỢC ĐỂ ĐÁNH BẠI KẺ THÙ CỦA BẠN',
  'Fight for the future of your people! Attack your enemies and defend your kingdom.':
    'Chiến đấu cho tương lai của người dân của bạn! Tấn công kẻ thù của bạn và bảo vệ vương quốc của bạn.',

  'DISCOVER THE SECRETS BEHIND OUR LANDS': 'KHÁM PHÁ BÍ MẬT SAU ĐẤT NỀN CỦA CHÚNG TÔI',
  'In an arid desert world, mysterious groups of mountains hidden paradises full of wealth. What is the origin of the valleys? What drives our characters to their destiny?':
    'Trong một thế giới sa mạc khô cằn, những nhóm núi bí ẩn ẩn chứa những thiên đường giàu có. Nguồn gốc của các thung lũng là gì? Điều gì thúc đẩy các nhân vật của chúng ta đến số phận của họ?',

  'PLAY ALONE OR WITH FRIENDS': 'CHƠI MỘT MÌNH HOẶC VỚI BẠN BÈ',
  'Be the protagonist of your own story or unify your strength with other people.':
    'Trở thành nhân vật chính trong câu chuyện của chính bạn hoặc hợp nhất sức mạnh của bạn với những người khác.',

  'PLAY TO OWN': 'CHƠI ĐỂ SỞ HỮU',
  'What you buy is yours forever! Get the best cards and customize your appearance.':
    'Những gì bạn mua là của bạn mãi mãi! Nhận các thẻ tốt nhất và tùy chỉnh giao diện của bạn.',

  PARTNERS: 'ĐỐI TÁC',
  'ABOUT US': 'GIỚI THIỆU',
  HELP: 'TRỢ GIÚP',
  COMMUNITY: 'CỘNG ĐỒNG',
  community_note: 'Điều khoản và Điều kiện | Chính sách bảo mật',
  'Our Team': 'Đội ngũ',
  'Contact Us': 'Liên hệ chúng tôi',
  "Don't have an account?": 'Bạn chưa có tài khoản?',
  'Create Account': 'Tạo tài khoản',
  'Already have an account?': 'Đã có tài khoản?',
}
