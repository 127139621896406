export const translationFh = {
  profile: 'Profile',
  logout: 'Logout',
  login: 'Login',
  'sign up': 'Mag-sign up',
  'play now': 'Maglaro ngayon',
  lore: 'Lore',
  marketplace: 'Palengke',
  media: 'Media',
  'COMPETE FOR THE VICTORY': 'MAGKUPITAN PARA SA TAGUMPAY',
  'Play against other players to be the king or queen of the valley.':
    'Maglaro laban sa iba pang mga manlalaro upang maging hari o reyna ng lambak.',

  'MANAGE YOUR RESOURCES': 'PAMAHALAAN ANG IYONG MGA RESOURCES',
  'Create weapons, improve your troops and select your perfect deck.':
    'Lumikha ng mga armas, pagbutihin ang iyong mga tropa at piliin ang iyong perpektong deck.',

  'CREATE STRATEGIES TO DEFEAT YOUR ENEMIES':
    'GUMAWA NG MGA ESTRATEHIYA PARA MATAGI ANG IYONG MGA KAAWAY',
  'Fight for the future of your people! Attack your enemies and defend your kingdom.':
    'Ipaglaban ang kinabukasan ng iyong bayan! Atakihin ang iyong mga kaaway at ipagtanggol ang iyong kaharian.',

  'DISCOVER THE SECRETS BEHIND OUR LANDS': 'TUKLASIN ANG MGA SIKRETO SA LIKOD NG ATING MGA LUPA',
  'In an arid desert world, mysterious groups of mountains hidden paradises full of wealth. What is the origin of the valleys? What drives our characters to their destiny?':
    'Sa isang tigang na disyerto na mundo, ang mga misteryosong grupo ng mga bundok ay nagtatago ng mga paraiso na puno ng yaman. Ano ang pinagmulan ng mga lambak? Ano ang nagtutulak sa ating mga karakter sa kanilang kapalaran?',

  'PLAY ALONE OR WITH FRIENDS': 'MAGLARO MAG-ISA O SA MGA KAIBIGAN',
  'Be the protagonist of your own story or unify your strength with other people.':
    'Maging bida sa sarili mong kwento o pag-isahin ang iyong lakas sa ibang tao.',

  'PLAY TO OWN': 'MAGLARO SA PAG-AARI',
  'What you buy is yours forever! Get the best cards and customize your appearance.':
    'Yung bibilhin mo, forever mo na! Kunin ang pinakamahusay na mga card at i-customize ang iyong hitsura.',
  PARTNERS: 'PARTNERS',

  'ABOUT US': 'TUNGKOL SA ATIN',
  HELP: 'TULONG',
  COMMUNITY: 'KOMUNIDAD',
  community_note: 'Mga Tuntunin at Kundisyon | Patakaran sa Privacy',
  'Our Team': 'Ang aming koponan',
  'Contact Us': 'Makipag-ugnayan',
  "Don't have an account?": 'Walang account?',
  'Create Account': 'Gumawa ng account',
  'Already have an account?': 'Mayroon nang account?',
}
